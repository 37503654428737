@use '../variables/colors.scss' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/variables.scss' as *;

.input-field {
  position: relative;

  input {
    @include typo-make-text;
  }

  &.not-grid-size {
    width: 440px;
  }

  & + .input-field {
    margin-top: $indent-24;

    @include screen-small-down {
      margin-top: 12px;
  }
  }

  &.disabled {
    .input-field__label {
      opacity: .3;
      pointer-events: none;
      color: $gray-deep;
    }

    input,
    textarea {
      pointer-events: none;
    }

    textarea {
      &::-webkit-resizer {
        background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1L1 11.5' stroke='%2371717C' stroke-opacity='0.3'/%3E%3Cpath d='M11.5 7L6.5 12' stroke='%2371717C' stroke-opacity='0.3'/%3E%3C/svg%3E%0A");
        opacity: .3;
      }
    }
  }
}

%input-field-input {
  width: 100%;
  color: $dark-color;
  border: 1px solid $gray-middle;
  transition:
    border-color .3s,
    box-shadow .3s;
  border-radius: 16px;
  outline: none;

  &::placeholder {
    color: $gray-deep;
  }

  &[disabled] {
    color: $gray-blue;
    opacity: 0.64;
    background-color: transparent;

    & + .input-field__label {
      color: $gray-deep;
      opacity: 0.6;
      transition-duration: .1s;
      transition-delay: 0s;
    }
  }

  &:focus {
    box-shadow: 0px 2px 16px rgba(26, 26, 63, .08);

    & + .input-field__label {
      transform: translate(16px, 10px) scale(.625);

      @include screen-small-down {
        transform: translate(16px, 4px) scale(.625);
      }
    }
  }

  &:hover:not(:focus){
    border-color: $violet-extra-light;
  }
}

.input-field__label {
  position: absolute;
  top: 0;
  left: 0;
  color: $gray-deep;

  @include typo-make-text;

  transform: translate(16px, 16px) scale(1);
  transition:
    color .2s cubic-bezier(0, 0, .2, 1),
    transform .2s cubic-bezier(0, 0, .2, 1);
  transform-origin: 0 0;
  pointer-events: none;

  @include screen-small-down {
    transform: translate(16px, 14px) scale(1);
  }

  &.active {
    transform: translate(16px, 10px) scale(.625);

    @include screen-small-down {
      transform: translate(16px, 4px) scale(.625);
    }
  }

  @include screen-small-down {
    transform: translate(16px, 12px) scale(1);
  }
}

%input-field-input-error {
  border-color: $error-field;
  box-shadow: 0px 2px 16px rgba(255, 42, 118, .24);
}

.input-field__error {
  @include typo-make-tiny;
  color: $error-field;

  &:not(:empty) {
    margin-top: 4px;
  }
}

textarea {
  min-height: 130px;
  line-height: 1.5;
  max-width: 100%;
  min-width: 100%;

  &::-webkit-resizer {
    height: 13px;
    width: 12px;
    background-image: url("data:image/svg+xml,%3Csvg width='12' height='13' viewBox='0 0 12 13' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M11.5 1L1 11.5' stroke='%2326AFCD'/%3E%3Cpath d='M11.5 7L6.5 12' stroke='%2326AFCD'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
  }
}

.input-field {
  input,
  textarea {
    padding: calc($indent-20 + 2px) $indent-16 calc($indent-8 + 2px);
    @extend %input-field-input;

    @include screen-small-down {
      height: 48px;
      padding: 12px 16px;
      font-size: 14px;
      line-height: 20px;
    }
  }

  .error {
    input,
    textarea,
    .select__control,
    .checkbox:not(.hover):not(.active) {
      @extend %input-field-input-error;
    }
  }

  .select__dropdown-icon {
    fill: $color-bluemarin;
  }
}

.search__field {
  input {
    padding: $indent-16;
    padding-left: 56px;
  }
}
