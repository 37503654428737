
////
/// Design system's colors.
/// @group colors
/// @type Color
/// @author Alisa Dukina <a.dukina@make.st>
////

/// The main accent color. Used for interactive elements
$bluemarin: #26AFCD;

/// The light blue color. Used for the `:hover` state interactive elements
$bluemarin-light: #20C5E9;

/// The extra light blue color
$bluemarin-extra-light: #38DBFF;
/// The blue dark color
$blue-dark: #385BB4;
$blue-card: #86AEF2;
$blue-extra-dark: #3C4D80;
$blue-extra-light: #7C96FF;

/// The blue color
$blue: #5171F2;

/// the blue light color
$blue-light: #6180FF;

$elka-color: #D2EAFF;

/// The blue gray color
$blue-gray: #505982;

/// The default text color
$dark-color: #00001E;

// The discussion text color
$discussion-text: #11101C;

/// The white color
$white: #ffffff;

/// The middle gray background
$gray-middle: #AEB3CC;
$gray-background-light: #E2E5F7;

/// The gray text
$gray: #C6C9DD;

///The gray blue text
$gray-blue: #676C8D;

/// The deep gray color
$gray-deep: #71717C;

/// The gray light color
$gray-light: #E1E4F1;
$gray-strong: #E5E5E5;
$gray-background: #F5F5F5;

/// The extra light gray color
$gray-extra-light: #F1F1F7;

// The gray afisha background
$gray-medium: #E6E7F1;

/// The violet color
$violet: #9164F0;
$violet-light: #A87FFF;
$violet-extra-light: #B390FF;
$mask-slide: #9194F7;

/// The black color
$black: #000000;
$black-blueish: #00001d;
$black-lighter: #11101C;
$black-gray: #404067;

$error: #FF0000;
$error-field: #FF2A76;
$error-field-lighter: #FF7676;

$error-light: #FF9788 ;
$orange: #FFB27B;
$pink: #CC8CCF;

$yellow-yamal: #E3F655;
$logo-text-color: #3C4D80;

$steel-blue: #cad1fc;
$steel-blue-darker: #929cd9;