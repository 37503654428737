////
/// Global variables for the indent system.
/// @group indent-system
/// @type Length
/// @author Daniil Filippov <d.filippov@make.st>
////

@use "../mixins/media-queries" as *;
@use "../functions/linear-interpolation" as *;
@use "grid" as *;

/// The indents values for mobile phones. Keys are indent values
/// for the desktop, values are appropriate indents for mobile phones.
/// @type Map
$indents-mobile: (
  8: 8,
  12: 8,
  16: 12,
  20: 16,
  24: 16,
  32: 24,
  40: 32,
  48: 32,
  64: 40,
  80: 48,
) !default;

@function _small-sizes-interpolation($min, $max) {
  @return linear-interpolation($grid-container-small-min, $min, $grid-container-small-max, $max);
}

@function _medium-sizes-interpolation($min, $max) {
  @return linear-interpolation($grid-container-medium-min, $min, $grid-container-medium-max, $max);
}

@function _large-sizes-interpolation($min, $max) {
  @return linear-interpolation($grid-container-large-min, $min, $grid-container-large-max, $max);
}

@function _huge-sizes-interpolation($min, $max) {
  @return linear-interpolation($grid-container-huge-min, $min, $grid-container-huge-max, $max);
}

@function _oversized-sizes-interpolation($min, $max) {
  @return linear-interpolation($grid-container-oversized-min, $min, $grid-container-oversized-max, $max);
}

$_indent-96-size-small-down: 40px;
$_indent-96-size-oversized-up: 96px;
$_indent-96-size-small: _small-sizes-interpolation($_indent-96-size-small-down, 48px);
$_indent-96-size-medium: _medium-sizes-interpolation(48px, 64px);
$_indent-96-size-large: _large-sizes-interpolation(64px, 72px);
$_indent-96-size-huge: _huge-sizes-interpolation(72px, $_indent-96-size-oversized-up);
$_indent-96-size-oversized:$_indent-96-size-oversized-up;

$_indent-80-size-small-down: 20px;
$_indent-80-size-oversized-up: 80px;
$_indent-80-size-small: _small-sizes-interpolation($_indent-80-size-small-down, 48px);
$_indent-80-size-medium: _medium-sizes-interpolation(48px, 64px);
$_indent-80-size-large: _large-sizes-interpolation(64px, 72px);
$_indent-80-size-huge: _huge-sizes-interpolation(72px, $_indent-80-size-oversized-up);
$_indent-80-size-oversized: $_indent-80-size-oversized-up;

$_indent-72-size-small-down: 32px;
$_indent-72-size-oversized-up: 72px;
$_indent-72-size-small: _small-sizes-interpolation($_indent-72-size-small-down, 36px);
$_indent-72-size-medium: _medium-sizes-interpolation(36px, 48px);
$_indent-72-size-large: _large-sizes-interpolation(48px, 56px);
$_indent-72-size-huge: _huge-sizes-interpolation(56px, $_indent-72-size-oversized-up);
$_indent-72-size-oversized: $_indent-72-size-oversized-up;

$_indent-64-size-small-down: 32px;
$_indent-64-size-oversized-up: 64px;
$_indent-64-size-small: _small-sizes-interpolation($_indent-64-size-small-down, 36px);
$_indent-64-size-medium: _medium-sizes-interpolation(36px, 48px);
$_indent-64-size-large: _large-sizes-interpolation(48px, 56px);
$_indent-64-size-huge: _huge-sizes-interpolation(56px, $_indent-64-size-oversized-up);
$_indent-64-size-oversized: $_indent-64-size-oversized-up;

$_indent-56-size-small-down: 32px;
$_indent-56-size-oversized-up: 56px;
$_indent-56-size-small: _small-sizes-interpolation($_indent-56-size-small-down, 36px);
$_indent-56-size-medium: _medium-sizes-interpolation(36px, 48px);
$_indent-56-size-large: _large-sizes-interpolation(48px, 56px);
$_indent-56-size-huge: _huge-sizes-interpolation(56px, $_indent-56-size-oversized-up);
$_indent-56-size-oversized: $_indent-56-size-oversized-up;

$_indent-48-size-small-down: 28px;
$_indent-48-size-oversized-up: 48px;
$_indent-48-size-small: _small-sizes-interpolation($_indent-48-size-small-down, 32px);
$_indent-48-size-medium: _medium-sizes-interpolation(32px, 36px);
$_indent-48-size-large: _large-sizes-interpolation(36px, 40px);
$_indent-48-size-huge: _huge-sizes-interpolation(40px, $_indent-48-size-oversized-up);
$_indent-48-size-oversized: $_indent-48-size-oversized-up;

$_indent-40-size-small-down: 28px;
$_indent-40-size-oversized-up: 40px;
$_indent-40-size-small: $_indent-40-size-small-down;
$_indent-40-size-medium: _medium-sizes-interpolation(28px, 32px);
$_indent-40-size-large: _large-sizes-interpolation(32px, 36px);
$_indent-40-size-huge: _huge-sizes-interpolation(36px, $_indent-40-size-oversized-up);
$_indent-40-size-oversized: $_indent-40-size-oversized-up;

$_indent-36-size-small-down: 24px;
$_indent-36-size-oversized-up: 36px;
$_indent-36-size-small: $_indent-36-size-small-down;
$_indent-36-size-medium: $_indent-36-size-small-down;
$_indent-36-size-large: _large-sizes-interpolation($_indent-36-size-small-down, 32px);
$_indent-36-size-huge: _huge-sizes-interpolation(32px, $_indent-36-size-oversized-up);
$_indent-36-size-oversized: $_indent-36-size-oversized-up;

$_indent-32-size-small-down: 24px;
$_indent-32-size-oversized-up: 32px;
$_indent-32-size-small: $_indent-32-size-small-down;
$_indent-32-size-medium: $_indent-32-size-small-down;
$_indent-32-size-large: _large-sizes-interpolation($_indent-32-size-small-down, 32px);
$_indent-32-size-huge: _huge-sizes-interpolation(32px, $_indent-32-size-oversized-up);
$_indent-32-size-oversized: $_indent-32-size-oversized-up;

$_indent-24-size-small-down: 24px;
$_indent-24-size-oversized-up: $_indent-24-size-small-down;
$_indent-24-size-small: $_indent-24-size-small-down;
$_indent-24-size-medium: $_indent-24-size-small-down;
$_indent-24-size-large: $_indent-24-size-small-down;
$_indent-24-size-huge: $_indent-24-size-small-down;
$_indent-24-size-oversized: $_indent-24-size-small-down;

$_indent-20-size-small-down: 20px;
$_indent-20-size-oversized-up: $_indent-20-size-small-down;
$_indent-20-size-small: $_indent-20-size-small-down;
$_indent-20-size-medium: $_indent-20-size-small-down;
$_indent-20-size-large: $_indent-20-size-small-down;
$_indent-20-size-huge: $_indent-20-size-small-down;
$_indent-20-size-oversized: $_indent-20-size-small-down;

$_indent-16-size-small-down: 16px;
$_indent-16-size-oversized-up: $_indent-16-size-small-down;
$_indent-16-size-small: $_indent-16-size-small-down;
$_indent-16-size-medium: $_indent-16-size-small-down;
$_indent-16-size-large: $_indent-16-size-small-down;
$_indent-16-size-huge: $_indent-16-size-small-down;
$_indent-16-size-oversized: $_indent-16-size-small-down;

$_indent-12-size-small-down: 12px;
$_indent-12-size-oversized-up: $_indent-12-size-small-down;
$_indent-12-size-small: $_indent-12-size-small-down;
$_indent-12-size-medium: $_indent-12-size-small-down;
$_indent-12-size-large: $_indent-12-size-small-down;
$_indent-12-size-huge: $_indent-12-size-small-down;
$_indent-12-size-oversized: $_indent-12-size-small-down;

$_indent-8-size-small-down: 8px;
$_indent-8-size-oversized-up: $_indent-8-size-small-down;
$_indent-8-size-small: $_indent-8-size-small-down;
$_indent-8-size-medium: $_indent-8-size-small-down;
$_indent-8-size-large: $_indent-8-size-small-down;
$_indent-8-size-huge: $_indent-8-size-small-down;
$_indent-8-size-oversized: $_indent-8-size-small-down;

:root {
  --indent-96-size: #{$_indent-96-size-small-down};
  --indent-80-size: #{$_indent-80-size-small-down};
  --indent-72-size: #{$_indent-72-size-small-down};
  --indent-64-size: #{$_indent-64-size-small-down};
  --indent-56-size: #{$_indent-56-size-small-down};
  --indent-48-size: #{$_indent-48-size-small-down};
  --indent-40-size: #{$_indent-40-size-small-down};
  --indent-36-size: #{$_indent-36-size-small-down};
  --indent-32-size: #{$_indent-32-size-small-down};
  --indent-24-size: #{$_indent-24-size-small-down};
  --indent-20-size: #{$_indent-20-size-small-down};
  --indent-16-size: #{$_indent-16-size-small-down};
  --indent-12-size: #{$_indent-12-size-small-down};
  --indent-8-size: #{$_indent-8-size-small-down};

  @include screen-small-up {
    --indent-96-size: #{$_indent-96-size-small};
    --indent-80-size: #{$_indent-80-size-small};
    --indent-72-size: #{$_indent-72-size-small};
    --indent-64-size: #{$_indent-64-size-small};
    --indent-56-size: #{$_indent-56-size-small};
    --indent-48-size: #{$_indent-48-size-small};
    --indent-40-size: #{$_indent-40-size-small};
    --indent-36-size: #{$_indent-36-size-small};
    --indent-32-size: #{$_indent-32-size-small};
    --indent-24-size: #{$_indent-24-size-small};
    --indent-20-size: #{$_indent-20-size-small};
    --indent-16-size: #{$_indent-16-size-small};
    --indent-12-size: #{$_indent-12-size-small};
    --indent-8-size: #{$_indent-8-size-small};
  }

  @include screen-medium-up {
    --indent-96-size: #{$_indent-96-size-medium};
    --indent-80-size: #{$_indent-80-size-medium};
    --indent-72-size: #{$_indent-72-size-medium};
    --indent-64-size: #{$_indent-64-size-medium};
    --indent-56-size: #{$_indent-56-size-medium};
    --indent-48-size: #{$_indent-48-size-medium};
    --indent-40-size: #{$_indent-40-size-medium};
    --indent-36-size: #{$_indent-36-size-medium};
    --indent-32-size: #{$_indent-32-size-medium};
    --indent-24-size: #{$_indent-24-size-medium};
    --indent-20-size: #{$_indent-20-size-medium};
    --indent-16-size: #{$_indent-16-size-medium};
    --indent-12-size: #{$_indent-12-size-medium};
    --indent-8-size: #{$_indent-8-size-medium};
  }

  @include screen-large-up {
    --indent-96-size: #{$_indent-96-size-large};
    --indent-80-size: #{$_indent-80-size-large};
    --indent-72-size: #{$_indent-72-size-large};
    --indent-64-size: #{$_indent-64-size-large};
    --indent-56-size: #{$_indent-56-size-large};
    --indent-48-size: #{$_indent-48-size-large};
    --indent-40-size: #{$_indent-40-size-large};
    --indent-36-size: #{$_indent-36-size-large};
    --indent-32-size: #{$_indent-32-size-large};
    --indent-24-size: #{$_indent-24-size-large};
    --indent-20-size: #{$_indent-20-size-large};
    --indent-16-size: #{$_indent-16-size-large};
    --indent-12-size: #{$_indent-12-size-large};
    --indent-8-size: #{$_indent-8-size-large};
  }

  @include screen-huge-up {
    --indent-96-size: #{$_indent-96-size-huge};
    --indent-80-size: #{$_indent-80-size-huge};
    --indent-72-size: #{$_indent-72-size-huge};
    --indent-64-size: #{$_indent-64-size-huge};
    --indent-56-size: #{$_indent-56-size-huge};
    --indent-48-size: #{$_indent-48-size-huge};
    --indent-40-size: #{$_indent-40-size-huge};
    --indent-36-size: #{$_indent-36-size-huge};
    --indent-32-size: #{$_indent-32-size-huge};
    --indent-24-size: #{$_indent-24-size-huge};
    --indent-20-size: #{$_indent-20-size-huge};
    --indent-16-size: #{$_indent-16-size-huge};
    --indent-16-size: #{$_indent-16-size-huge};
    --indent-12-size: #{$_indent-12-size-huge};
    --indent-8-size: #{$_indent-8-size-huge};
  }

  @include screen-oversized-up {
    --indent-96-size: #{$_indent-96-size-oversized};
    --indent-80-size: #{$_indent-80-size-oversized};
    --indent-72-size: #{$_indent-72-size-oversized};
    --indent-64-size: #{$_indent-64-size-oversized};
    --indent-56-size: #{$_indent-56-size-oversized};
    --indent-48-size: #{$_indent-48-size-oversized};
    --indent-40-size: #{$_indent-40-size-oversized};
    --indent-36-size: #{$_indent-36-size-oversized};
    --indent-32-size: #{$_indent-32-size-oversized};
    --indent-24-size: #{$_indent-24-size-oversized};
    --indent-20-size: #{$_indent-20-size-oversized};
    --indent-16-size: #{$_indent-16-size-oversized};
    --indent-12-size: #{$_indent-12-size-oversized};
    --indent-8-size: #{$_indent-8-size-oversized};
  }

  @include screen-min($grid-container-oversized-max + 1px) {
    --indent-96-size: #{$_indent-96-size-oversized-up};
    --indent-80-size: #{$_indent-80-size-oversized-up};
    --indent-72-size: #{$_indent-72-size-oversized-up};
    --indent-64-size: #{$_indent-64-size-oversized-up};
    --indent-56-size: #{$_indent-56-size-oversized-up};
    --indent-48-size: #{$_indent-48-size-oversized-up};
    --indent-40-size: #{$_indent-40-size-oversized-up};
    --indent-36-size: #{$_indent-36-size-oversized-up};
    --indent-32-size: #{$_indent-32-size-oversized-up};
    --indent-24-size: #{$_indent-24-size-oversized-up};
    --indent-20-size: #{$_indent-20-size-oversized-up};
    --indent-16-size: #{$_indent-16-size-oversized-up};
    --indent-12-size: #{$_indent-12-size-oversized-up};
    --indent-8-size: #{$_indent-8-size-oversized-up};
  }
}


$indent-96: var(--indent-96-size);
/// The design system's "80" indent's variable size.
$indent-80: var(--indent-80-size);
/// The design system's "72" indent's variable size.
$indent-72: var(--indent-72-size);
/// The design system's "64" indent's variable size.
$indent-64: var(--indent-64-size);
/// The design system's "56" indent's variable size.
$indent-56: var(--indent-56-size);
/// The design system's "48" indent's variable size.
$indent-48: var(--indent-48-size);
/// The design system's "40" indent's variable size.
$indent-40: var(--indent-40-size);
/// The design system's "36" indent's variable size.
$indent-36: var(--indent-36-size);
/// The design system's "32" indent's variable size.
$indent-32: var(--indent-32-size);
/// The design system's "24" indent's variable size.
$indent-24: var(--indent-24-size);
/// The design system's "20" indent's variable size.
$indent-20: var(--indent-20-size);
/// The design system's "16" indent's variable size.
$indent-16: var(--indent-16-size);
/// The design system's "12" indent's variable size.
$indent-12: var(--indent-12-size);
/// The design system's "8" indent's variable size.
$indent-8: var(--indent-8-size);
