@use '../../styles/variables.scss' as *;
@use '../../styles/variables/indent-system.scss' as *;
@use '../../styles/mixins.scss' as *;
@use '../../styles/placeholders.scss' as *;

.input-range__slider {
  position: absolute;
  top: 0;
  left: -50%;
  background-image: url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M8.19473 0C10.5904 0 12.6448 1.06506 14.2017 2.91495L14.2733 3C15.825 4.84369 18.6204 5.92993 21 5.99673V10.0033C18.6204 10.0701 15.825 11.1563 14.2733 13L14.2017 13.0851C12.6448 14.9349 10.5904 16 8.19473 16H7.87492C3.52572 16 0 12.4183 0 8C0 3.58173 3.52572 0 7.87492 0H8.19473Z' fill='%23EBC2C7'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
  height: 16px;
  width: 21px;
  transform: translateX(10%);
}

.input-range__slider-container {
  z-index: 2;
  position: absolute;
  top: calc(-100% - 2px);
  height: 16px;
  width: 21px;
  transition: left .2s ease-out;

  & + .input-range__slider-container {
    transform: translateX(-100%);

    .input-range__label--value {
      transform: translateX(40%);
      left: -50%;
    }

    .input-range__slider {
      background-image: url("data:image/svg+xml,%3Csvg width='21' height='16' viewBox='0 0 21 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M12.8053 16C10.4096 16 8.35521 14.9349 6.79827 13.0851L6.72672 13C5.17501 11.1563 2.37962 10.0701 5.24251e-07 10.0033L8.74513e-07 5.99673C2.37962 5.92993 5.17501 4.84369 6.72672 3L6.79827 2.91495C8.35521 1.06506 10.4096 -9.25846e-07 12.8053 -7.16406e-07L13.1251 -6.88447e-07C17.4743 -3.08229e-07 21 3.58173 21 8C21 12.4183 17.4743 16 13.1251 16L12.8053 16Z' fill='%2388DAE7'/%3E%3C/svg%3E%0A");
      left: 50%;
      transform: translateX(-10%);
    }
  }
}

.input-range__label--min,
.input-range__label--max {
  display: none;
}

.input-range__label--min {
  left: 0;
}

.input-range__label--max {
  right: 0;
}

.input-range__label--value {
  position: relative;
  top: calc(-100% - 4px);
  display: block;
  height: 20px;
  width: 35px;
  transform: translateX(-50%);
  text-align: center;
}

.input-range__label-container {
  position: relative;
  @include typo-medium-text;
  color: $color-black;
}

.input-range__track {
  position: relative;
  background: $color-gray-light;
  border-radius: 2px;
  transition:
    left .2s ease-out,
    width .2s ease-out;
}

.input-range__track--active {
  background: linear-gradient(270deg, #87D9E6 0%, #8994F8 33%, #B392F4 68%, #EBC2C6 100%);
  height: 4px;
}

.input-range {
  position: relative;
  width: 100%;
}
