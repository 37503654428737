@use '../variables' as *;
@use '../mixins' as *;
@use '../placeholders' as *;
@use "sass:math";

.react-modal-sheet-backdrop {
  inset: 0 !important;
  opacity: .5 !important;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)) !important;
  background-image: url('../../../public/images/modal-bg-min.jpg') !important;
  background-size: 100% 100% !important;
  background-repeat: no-repeat !important;
}
.react-modal-sheet-container {
  max-height: 100% !important;
  box-shadow: 0px 8px 48px 0px rgba(0, 16, 94, 0.10) !important;
}
.react-modal-sheet-header {
  position: absolute !important;
  height: 48px !important;
  align-items: flex-start !important;

  span {
    position: relative !important;
    display: inline-block;
    width: 20px !important;
    height: 7px !important;
    border-radius: 63px !important;
    background-color: $color-gray-middle !important;
    top: -3.5px !important;
    z-index: 2;
  }
}

// .react-modal-sheet-drag-indicator {
// }

.react-modal-sheet-content {
  margin-top: calc($indent-24 * -1); // .headerWrapper height
}

.react-modal-sheet-scroller {
  padding-top: $indent-24;
  -ms-overflow-style: none !important;
  scrollbar-width: none !important;

  &::-webkit-scrollbar {
    display: none !important;
  }
}

.react-modal-sheet-gradient-border {
  &::before {
    @extend %border-gradient;
    background: linear-gradient(50.39deg, #54E8D3 5.88%, #5166F5 55.27%, #9963EF 107.13%, #FFC194 154.78%),
      linear-gradient(0deg, #FFFFFF, #FFFFFF),
      linear-gradient(0deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3));
    background-origin: border-box;
    z-index: 1;
  }
}